.form-page {
  .page-wrap {
    text-align: center;
    background: $palette-primary-dark;
    min-height: 100%;
    position: relative;
    width: 100%;
    align-items: center;
    padding: spacing(10, 5);
    @include breakpoints-up(md) {
      display: flex;
    }
    @include breakpoints-down(sm) {
      padding: spacing(5, 0)
    }
    .title-contact {
      color: $palette-common-white
    }
    a {
      @include use-theme(color, $palette-primary-main, $palette-primary-light);
      text-transform: none;
      font-size: 16px;
      text-decoration: none;
      font-weight: $font-regular;
      &.btn-link {
        font-weight: $font-bold
      }
    }
    .inner-wrap {
      &.container {
        #main-wrap & {
          width: 100%;
        }
      }
      position: static;
      @include text-align(left)
    }
    .form-wrap {
      @include palette-background-paper;
      position: relative;
      padding: $spacing2;
      @include breakpoints-up(sm) {
        padding: $spacing5
      }
      @include breakpoints-up(lg) {
        padding: $spacing8
      }
      @include breakpoints-down(sm) {
        border-radius: 40px;
      }
    }
    .full-form-wrap {
      color: $palette-common-white;
      padding-top: $spacing8;
      @include breakpoints-down(xs) {
        padding-top: $spacing4
      }
    }
    .form-box {
      border-radius: 40px !important;
      background: transparent;
      box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.28);
      overflow: visible;
      @include breakpoints-up(md) {
        overflow: hidden;
      }
      @include breakpoints-down(xs) {
        box-shadow: none
      }
      .backtohome {
        @include breakpoints-down(sm) {
          top: -150px
        }
      }
    }
  }

  .logo {
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing3;
    position: relative;
    z-index: 1;
    &.logoHeader {
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10
    }
    img {
      width: 64px
    }
    p, span {
      display: block;
      padding-bottom: 4px;
      color: $palette-common-white
    }
  }
  
  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    a {
      margin-top: $spacing2;
      i {
        [dir="rtl"] & {
          transform: rotate(180deg);
        }
      }
    }
    @include breakpoints-down(sm) {
      margin-top: $spacing2;
      justify-content: center;
      a {
        display: none
      }
    }
  }

  .desc {
    color: $palette-common-white;
    text-align: center;
    padding: spacing(0, 10);
    @include breakpoints-down(xs) {
      padding: spacing(0, 2)
    }
  }

  .input {
    width: 100%;
    @include left(4px)
  }

  .form {
    @include text-align(left);
    position: relative;
    margin-top: $spacing8;
    padding: spacing(0, 15, 10);
    @include breakpoints-down(sm) {
      padding: spacing(0, 4, 10)
    }
    @include breakpoints-down(xs) {
      padding: spacing(2, 1);
      margin-top: $spacing2
    }
  }
  
  .form-helper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    @include breakpoints-down(xs) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-area {
    [type=checkbox].filled-in:not(:checked) + span:not(.lever):after {
      border-color: rgba(0, 0, 0, 0.3)
    }
    justify-content: space-between;
    @include breakpoints-up(lg) {
      margin-top: $spacing3;
      display: flex;
    }
    @include breakpoints-down('md') {
      margin-top: $spacing8;
      button {
        margin-top: $spacing4;
        width: 100%
      }
    }
    @include breakpoints-down(xs) {
      margin-top: $spacing2;
      margin-bottom: $spacing5;
    }
    button.btn.btn-large {
      min-height: 48px;
      min-width: 180px;
    }
    a.link {
      text-decoration: none !important;
      color: $palette-secondary-main;
      margin-top: $spacing1;
    }
    &.flex {
      display: flex;
      justify-content: space-between;
      @include breakpoints-down(sm) {
        display: block
      }
    }
  }

  .right-icon {
    @include margin-left($spacing1)
  }

  .check {
    svg {
      fill: $palette-secondary-main
    }
  }

  .decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    @include left(0);
    overflow: hidden;
    clip: rect(0, auto, auto, 0);
    svg {
      fill: $palette-secondary-main;
      opacity: 0.2;
      position: fixed;
      top: 0;
    }
  }

  .left-deco {
    left: -400px;
    right: auto;
    width: 1200px;
    height: 1500px;
    transform-origin: top left;
    @include breakpoints-up(md) {
      transform: scale(0.8)
    }
  }

  .right-deco {
    left: auto;
    right: 0;
    height: 1500px;
    transform-origin: top right;
    @include breakpoints-up(md) {
      transform: scale(0.8)
    }
  }
  
  .auth-frame {
    display: block;
    position: relative;
    .row > div {
      padding: 0
    }
  }
  
  .greeting {
    display: flex;
    padding-top: $spacing16;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    text-align: center;
    color: $palette-common-white;
    h6 {
      font-weight: $font-regular
    }
  }

  .socmed-side-login {
    display: flex;
    justify-content: space-around;
    @include breakpoints-down(md) {
      justify-content: center
    }
    @include breakpoints-down(xs) {
      display: block
    }
    > * {
      color: $palette-common-white;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoints-down(md) {
        margin: 0 4px;
        width: 140px;
      }
      @include breakpoints-down(xs) {
        margin-bottom: $spacing2;
        width: 100%
      }
    }
    i {
      color: $palette-common-white;
      @include margin-right($spacing1)
    }
  }

  .blue-btn {
    background: #28aae1 !important;
    &:hover {
      background: darken(#28aae1, 20%) !important
    }
  }
  .navi-btn {
    background: #3b579d !important;
    &:hover {
      background: darken(#3b579d, 20%) !important
    }
  }
  .red-btn {
    background: #dd493c !important;
    &:hover {
      background: darken(#dd493c, 20%) !important
    }
  }
  .separator {
    margin: 40px auto;
    max-width: 300px;
    min-width: 200px;
    text-align: center;
    position: relative;
    p {
      @include breakpoints-down(xs) {
        font-size: 12px
      }
    }
    &:before, &:after {
      content: "";
      border-top: 1px solid;
      @include use-theme(border-top-color, $light-text-hint, $dark-text-hint);
      top: 50%;
      position: absolute;
      width: 20%
    }
    &:before {
      left: 0
    }
    &:after {
      right: 0
    }
  }

  .form-control-label {
    margin-top: 0;
    label {
      margin-top: 6px;
    }
    a {
      position: relative;
    }
    &.white-label {
      label {
        color: $palette-common-white
      }
    }
  }

  .backtohome {
    width: 80px;
    height: 80px;
    position: absolute;
    margin-top: 20px;
    z-index: 4;
    @include margin-left(20px);
    @include breakpoints-down(sm) {
      top: 150px;
      margin: 0
    }
    @include breakpoints-down(xs) {
      top: 10px;
      @include left(-20px)
    }
    span {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 80px;
    }
    i {
      line-height: 82px;
      font-size: 36px;
      color: rgba(255, 255, 255, 0.54)
    }
    > i:first-child {
      opacity: 1;
      transition: opacity 0.3s ease
    }
    > span i:last-child {
      position: absolute;
      @include right(0);
      opacity: 0;
      transition: all 0.3s ease
    }
    &:hover {
      @include breakpoints-up(sm) {
        > span i:first-child {
          opacity: 0;
        }
        > span i:last-child {
          @include right(27px);
          opacity: 1;
        }
      }
    }
  }
}

.toast {
  @include padding-right($spacing1);
  border-radius: 4px;
  width: auto;
  .toast-action {
    margin-right: 0;
  }
  i.material-icons {
    color: #FFF
  }
}